/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, jsx, SxProp, Themed} from 'theme-ui'
import React, {ReactNode} from 'react'
import MaleAvatar from '../graphics/person-male.svg'
import FemaleAvatar from '../graphics/person-female.svg'
import {Link} from 'gatsby'

type AvatarLinkProps = {
  name: string
  title: string
  slug: string
  gender: 'male' | 'female'
  size: 'medium' | 'large'
  children?: ReactNode
}

export const AvatarLink = ({name, title, slug, gender, size, children}: AvatarLinkProps) => (
  <Link
    to={slug}
    sx={{
      display: 'block',
      textAlign: 'center',
      color: 'unset',
      svg: {
        transition: 'background-color 0.25s',
        backgroundColor: 'white',
      },
      '&:hover': {
        color: 'unset',
        textDecoration: 'unset',
        svg: {
          backgroundColor: 'atxOrange',
        },
      },
    }}>
    <Box
      as={gender === 'male' ? MaleAvatar : FemaleAvatar}
      style={{width: size === 'large' ? '15rem' : '10rem'}}
    />
    {size === 'large'
      ? <Themed.h3 sx={{fontWeight: 'heavy', color: 'unset'}}>{name}</Themed.h3>
      : <Themed.h4 sx={{fontWeight: 'heavy', color: 'unset'}}>{name}</Themed.h4>}
    <Box sx={{fontWeight: 'bold'}}>{title}</Box>
  </Link>
)


type AvatarProps = {
  name: string
  title: string
  size: 'medium' | 'large'
  children?: ReactNode
}

export const Avatar = ({name, title, size, children}: AvatarProps) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem'
  }}>
    <Box
      className={size}
      sx={{
        '&.medium': {
          width: ['100%', '10rem'],
        },
        '&.large': {
          width: ['100%', '16rem'],
        },
      }}>
      {children}
    </Box>
    {size === 'large'
      ? <Themed.h3 sx={{fontWeight: 'heavy', color: 'unset'}}>{name}</Themed.h3>
      : <Themed.h4 sx={{fontWeight: 'heavy', color: 'unset'}}>{name}</Themed.h4>}
    <Box sx={{fontWeight: 'bold'}}>{title}</Box>
  </Box>
)

