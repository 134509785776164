/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, Flex, jsx, Themed} from 'theme-ui'
import React from 'react'
import {Layout} from '../layout/layout'
import {StaticImage} from 'gatsby-plugin-image'
import {SectionGray, SectionImage, SectionWhite} from '../layout/section'
import {Avatar} from '../layout/avatar'

const PartnersAndProjects = () => (
  <Layout>
    <SectionImage image={(
      <StaticImage
        sx={{
          width: '100%',
          height: '100%',
        }}
        loading="eager"
        layout="fullWidth"
        src="../images/about-us.jpg"
        alt=""/>
    )}>
      <Themed.h1 sx={{
        color: 'white',
        marginBottom: ['2rem', '1rem'],
        fontWeight: 'regular',
      }}>
        The Faces behind ATX Defense.
      </Themed.h1>

      <div sx={{
        maxWidth: '37rem',
        fontWeight: 'regular',
      }}>
        With over 50 combined years of military service and 25 years of consulting experience, our team is uniquely
        positioned to bring an optimized approach to digital transformation.
      </div>
    </SectionImage>

    <SectionGray>
      <Box sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Themed.h2 sx={{
          fontWeight: 'bold',
          mb: '1rem',
        }}>
          THE FOUNDERS
        </Themed.h2>
        <Box sx={{display: 'flex', gap: '2rem', justifyContent: 'start', flexDirection: ['column', 'row']}}>
          <Box sx={{flexBasis: 0, flexGrow: 1, flexShrink: 1}}>
            <Avatar
              size="medium"
              name="Shawn Kotoske"
              title="Co-Founder">
              <StaticImage
                layout="constrained"
                loading="eager"
                placeholder="none"
                src="../images/headshots/ShawnHeadWeb.png"
                alt="Shawn Kotoske"/>
            </Avatar>
          </Box>
          <Box sx={{flexBasis: 0, flexGrow: 1, flexShrink: 1}}>
            <Avatar
              size="medium"
              name="Zach Walker"
              title="Co-Founder">
              <StaticImage
                layout="constrained"
                loading="eager"
                placeholder="none"
                src="../images/headshots/ZachHeadWeb.png"
                alt="Zach Walker"/>
            </Avatar>
          </Box>
        </Box>
      </Box>
    </SectionGray>

    <SectionWhite>
      <Box sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Themed.h2 sx={{
          color: 'atxOrange',
          fontWeight: 'bold',
        }}>
          MEET THE TEAM
        </Themed.h2>
        <Box sx={{display: 'flex', gap: '2rem', justifyContent: 'center', flexDirection: ['column', 'row']}}>
          <Box sx={{flexBasis: 0, flexGrow: 1, flexShrink: 1}}>
            <Avatar
              size="medium"
              name="Mike Allen"
              title="Director of Integration & Data Analytics">
              <StaticImage
                layout="constrained"
                placeholder="none"
                src="../images/headshots/MikeHeadWeb.png"
                alt="Mike Allen"/>
            </Avatar>
          </Box>
          {/* <Box sx={{flexBasis: 0, flexGrow: 1, flexShrink: 1}}>
            <Avatar
              size="medium"
              name="Jason Gregoryk"
              title="Staff Consultant, Transformation">
              <StaticImage
                layout="constrained"
                placeholder="none"
                src="../images/headshots/JasonHeadWeb.png"
                alt="Jason Gregoryk"/>
            </Avatar>
          </Box> */}
          <Box sx={{flexBasis: 0, flexGrow: 1, flexShrink: 1}}>
            <Avatar
              size="medium"
              name="Amber Slone"
              title="Chief Happiness Officer">
              <StaticImage
                layout="constrained"
                placeholder="none"
                src="../images/headshots/AmberHeadWeb.png"
                alt="Amber Slone"/>
            </Avatar>
          </Box>
        </Box>
      </Box>
    </SectionWhite>
  </Layout>
)

export default PartnersAndProjects
